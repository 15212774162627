import type Profile from '$lib/types';
import { readable, writable } from 'svelte/store';
import { navigating } from '$app/stores';

export const profileStore = writable<Profile | null>(null);

export interface Point {
	lat: number;
	lng: number;
}

export const locationStore = writable<Point | null>(null);

export const previousPage = readable<string | null>(null, (set) => {
	const unsubscribe = navigating.subscribe(($navigating) => {
		if ($navigating !== null && $navigating.from !== null) {
			set($navigating.from.url.pathname);
		}
	});

	return () => unsubscribe();
});
